import React from "react";
import "./Footer.css";

import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
      <div className="footer-links">
          <a><Link to="/privacy">Politique et Confidentialité</Link></a>
          <span>|</span>
          <a><Link to="/terms">Conditions d’utilisation</Link></a>
        </div>
        <div className="footer-bot">
          <p>© 2024 - Zeleph. Tous droits réservés</p>
        </div>
      </div>
      <div className="wave"></div>
    </footer>
  );
};

export default Footer;
