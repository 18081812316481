import React from 'react';
import '../App.css';

import Navbar from '../components/Navbar';
import MainSection from '../components/MainSection';
import TermsBand from '../components/terms/TermsBand'
import TermsSection from '../components/terms/TermsSection'
import Footer from '../components/Footer';

function Terms() {
  return (
    <div className="terms"> 
      <Navbar />
      <MainSection />
      <TermsBand />
      <TermsSection />
      <Footer />
    </div>
  );
}

export default Terms;