import React from 'react';
import '../Band.css';

const TermsBand = () => {
  return (
    <>
      <div className="band">
        <div className="band-text">
          <p>
            Les <a className="highlight">Termes</a> et <a className="highlight">Conditions</a> d'<a className="highlight">Utilisation</a>
          </p>
        </div>
        <div className="band-chat"></div>
      </div>
      <div className="band-thinner"></div>
    </>
  );
};

export default TermsBand;