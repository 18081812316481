import './App.css';

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop';

import Navbar from './components/Navbar';
import MainSection from './components/MainSection';
import HomeBand from './components/home/HomeBand';
import Footer from './components/Footer';
import HomeSection from './components/home/HomeSection';

import Terms from './pages/Terms'
import Privacy from './pages/Privacy'

import favicon from './assets/favicon.ico';

function App() {

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = favicon;
    document.head.appendChild(link);
    
    // Nettoyage de l'effet pour éviter la duplication
    return () => {
      const existingLink = document.querySelector("link[rel='icon']");
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, []);
  
  return (
    <Router>
      <ScrollToTop />
      <div className="app">
        <Routes>
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/" element={
            <>
              <Navbar />
              <MainSection />
              <HomeBand />
              <HomeSection />
              <Footer />
            </>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;