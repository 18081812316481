import React from 'react';
import '../Band.css';

const Band = () => {
  return (
    <>
      <div className="band">
        <div className="band-text">
          <p>
            Le bot privé de <a href="https://twitch.tv/lineette" className="highlight-lineette">Lineette</a> et <a href="https://twitch.tv/DJRomSTV" className="highlight-djroms">DJRomS</a>
          </p>
        </div>
        <div className="band-chat"></div>
      </div>
      <div className="band-thinner"></div>
    </>
  );
};

export default Band;