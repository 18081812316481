import React from 'react';
import './MainSection.css';

const MainSection = () => {
  return (
    <section className="main-section">
    </section>
  );
};

export default MainSection;
