import React from 'react';
import '../Section.css';

const TermsSection = () => {
  return (
    <div className="terms_section">
      <div className="terms">
        <div className="terms-content">

          <h2>Dernière mise à jour : 14 Août 2024</h2>
          <h2>1. Utilisation Personnelle</h2>
          <p>
            Le Bot est destiné à un usage strictement personnel et privé. Il est conçu pour être utilisé uniquement par son propriétaire et n'est pas destiné à être partagé, commercialisé ou distribué à des tiers.
          </p>

          <h2>2. Fonctionnalités du Bot</h2>
          <p>
            Le Bot fournit diverses fonctionnalités automatisées conçues pour faciliter certaines tâches spécifiques définies par le propriétaire. Ces fonctionnalités peuvent inclure, sans s'y limiter, la récupération d'informations en ligne, la gestion de tâches automatisées, et la communication via des plateformes telles que Discord ou d'autres outils de communication.
          </p>

          <h2>3. Propriété Intellectuelle</h2>
          <p>
            Vous reconnaissez que toutes les fonctionnalités, le code source, les scripts, et le contenu associés au Bot sont la propriété exclusive du propriétaire. Toute utilisation non autorisée ou reproduction du Bot est interdite.
          </p>

          <h2>4. Maintenance et Support</h2>
          <p>
            Le Bot est fourni "tel quel" sans aucune garantie de fonctionnement continu ou d'absence de bugs. Le propriétaire se réserve le droit de modifier, mettre à jour, ou arrêter le Bot à tout moment sans préavis.
          </p>

          <h2>5. Limitation de Responsabilité</h2>
          <p>
            Le propriétaire ne peut être tenu responsable des dommages, pertes, ou interruptions de service résultant de l'utilisation du Bot. Le Bot est utilisé à vos propres risques.
          </p>

          <h2>6. Modifications des Termes</h2>
          <p>
            Le propriétaire peut, à tout moment, modifier ces Termes. Les modifications prendront effet immédiatement après notification ou mise à jour sur le document de référence.
          </p>

      </div>
    </div>
    </div>
  );
};

export default TermsSection;
