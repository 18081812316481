import React, { useEffect } from 'react';
import '../Section.css';

import discordLogo from '../../assets/discord.svg';
import infoLogo from '../../assets/info.svg';

const HomeSection = () => {

  const openDiscordAuth = () => {
    const url = 'https://discord.com/oauth2/authorize?client_id=1269683733229867158&permissions=1248698232182&response_type=code&redirect_uri=https%3A%2F%2Fchillax.fr%2Fguild-oauth&integration_type=0&scope=guilds.join+bot+identify+messages.read+applications.commands';
    const width = 600; 
    const height = 800;
    const left = (window.innerWidth / 2) - (width / 2); 
    const top = (window.innerHeight / 2) - (height / 2);

    window.open(url, 'discordAuth', `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`);
  };

  useEffect(() => {
    const handleScroll = () => {
      const container = document.querySelector('.add-bot-container');
      if (container) {
        const rect = container.getBoundingClientRect();
        const isInViewport = rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
        if (isInViewport) {
          container.classList.add('visible');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Appel initial pour vérifier la position lors du chargement

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="home_section">
      <div className="glow"></div>
      <div className="add-bot-container">
        <h2>Ajoute ton bot sur ton <span>serveur discord</span></h2>
        <p>Vous souhaitez notifier vos viewers et vos abonnés quand vous postez une vidéo youtube?
          Zeleph est là pour vous servir, il notifiera toutes les personnes présentes sur votre 
          serveur quand vous lancerez un live Twitch ou bien quand vous posterez un TikTok ou une
          vidéo YouTube alors n'attendez plus !</p>
        <div className="button-container">
          <a onClick={(e) => { e.preventDefault(); openDiscordAuth(); }} className="discord-button" href="#">
            <img src={discordLogo} alt="Discord Logo" className="button-icon"/>
            <span className="button-text">Ajouter à discord</span>
          </a>
          <a href="#" className="info-button">
            <img src={infoLogo} alt="Question Mark" className="button-icon"/>
            <span className="button-text">En savoir plus</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeSection;
