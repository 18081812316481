import React, { useEffect, useState } from 'react';
import './Navbar.css';
import logo from '../assets/logo.svg';

function Navbar() {
  const [isHidden, setIsHidden] = useState(false);
  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop) {
        // Défilement vers le bas
        setIsHidden(true);
      } else {
        // Défilement vers le haut
        setIsHidden(false);
      }

      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // Pour éviter les valeurs négatives
    };

    window.addEventListener('scroll', handleScroll);

    // Nettoyer l'événement lorsqu'on démonte le composant
    return () => window.removeEventListener('scroll', handleScroll);
  }, []); // Le tableau vide [] signifie que l'effet est appelé une seule fois après le premier rendu

  return (
    <nav className={`navbar ${isHidden ? 'hidden' : ''}`}>
      <div className="navbar__container">
        <div className="navbar__left"> 
          <ul className="navbar__nav-links">
            <li className="navbar__nav-item"><a href="/">ACCUEIL</a></li>
          </ul>
        </div>

        <div className="navbar__logo-container"> 
          <img src={logo} alt="Logo" className="navbar__logo" /> 
        </div>

        <div className="navbar__right"> 
          <ul className="navbar__nav-links">
            <li className="navbar__nav-item"><a href="/">DISCORD</a></li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
