import React from 'react';
import '../App.css';

import Navbar from '../components/Navbar';
import MainSection from '../components/MainSection';
import PrivacyBand from '../components/privacy/PrivacyBand'
import PrivacySection from '../components/privacy/PrivacySection'
import Footer from '../components/Footer';

function Terms() {
  return (
    <div className="terms"> 
      <Navbar />
      <MainSection />
      <PrivacyBand />
      <PrivacySection />
      <Footer />
    </div>
  );
}

export default Terms;