import React from 'react';
import '../Section.css';

const PrivacySection = () => {
  return (
    <div className="privacy_section">
      <div className="terms">
        <div className="terms-content">

          <h2>Dernière mise à jour : 14 Août 2024</h2>
          <h2>1. Informations Collectées</h2>
          <p>
            Le Bot peut collecter des informations non personnelles, telles que des logs de fonctionnement, des statistiques d'utilisation, et d'autres données techniques nécessaires pour son bon fonctionnement. Aucune information personnelle identifiable n'est collectée à moins que cela ne soit explicitement programmé par le propriétaire pour un usage spécifique et localisé.
          </p>

          <h2>2. Utilisation des Informations</h2>
          <p>
            Toutes les informations collectées par le Bot sont strictement utilisées pour améliorer, dépanner, ou optimiser le fonctionnement du Bot. Aucune information n'est partagée avec des tiers, à moins que cela ne soit requis par la loi ou pour protéger les droits du propriétaire.
          </p>

          <h2>3. Sécurité des Données</h2>
          <p>
            Le propriétaire prend toutes les mesures raisonnables pour protéger les données collectées par le Bot contre tout accès non autorisé, altération ou destruction. Cela inclut des mesures de sécurité techniques, administratives, et physiques.
          </p>

          <h2>4. Conservation des Données</h2>
          <p>
            Les données collectées par le Bot sont conservées uniquement pour la durée nécessaire à la réalisation des finalités pour lesquelles elles ont été collectées. Le propriétaire peut supprimer ces données à tout moment et sans préavis.
          </p>

          <h2>5. Droits des Utilisateurs</h2>
          <p>
            Étant donné que le Bot est destiné à un usage personnel et privé, et qu'il n'est utilisé que par le propriétaire, les droits traditionnels des utilisateurs finaux (accès aux données, suppression des données, etc.) ne s'appliquent pas dans ce contexte.
          </p>

          <h2>6. Modifications de la Politique</h2>
          <p>
            Le propriétaire se réserve le droit de modifier cette Politique à tout moment. Les modifications prendront effet immédiatement après notification ou mise à jour sur le document de référence.
          </p>

          <h2>7. Contact</h2>
          <p>
            Pour toute question concernant cette Politique, veuillez contacter le propriétaire directement via les moyens de communication personnels.
          </p>

        </div>
      </div>
    </div>
  );
};

export default PrivacySection;
